import PropTypes from "prop-types";
import { theme, Icon } from "@washingtonpost/wpds-ui-kit";

// wpds-assets
import {
  Comment,
  Crossword,
  Horoscopes,
  Keyword,
  Mahjongg,
  OnTheRecord,
  Solitaire,
  Sudoku
} from "@washingtonpost/wpds-assets";

const Item = ({ linkWrapperClass, iconName, item, itemUrl }) => {
  const itemStyle = {
    marginRight: theme.space["025"],
    color: theme.colors.gray40
  };
  const icons = {
    comment: Comment,
    crossword: Crossword,
    keyword: Keyword,
    mahjongg: Mahjongg,
    sudoku: Sudoku,
    horoscopes: Horoscopes,
    solitaire: Solitaire,
    ontherecord: OnTheRecord
  };
  const GameIcon = icons[iconName];
  return (
    <a
      className={linkWrapperClass}
      href={itemUrl}
      style={{ whiteSpace: "nowrap" }}
    >
      <Icon label="" style={itemStyle}>
        <GameIcon />
      </Icon>
      <span>{item}</span>
    </a>
  );
};

Item.propTypes = {
  iconName: PropTypes.string,
  iconVariant: PropTypes.string,
  item: PropTypes.string,
  itemUrl: PropTypes.string,
  linkWrapperClass: PropTypes.string
};

export default Item;
